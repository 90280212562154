import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { concatMap, forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { HelperService } from '../helper.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import {
  cardReaderDisplayTypeTranslations,
  cardReaderTypeTranslations,
  IHexboxDataOutput,
  ISensorExcelData,
  LineDropdownOption,
  SensorBulkUpdateRequestInterface,
  SensorCRUDCreateRequestInterface,
  SensorCRUDInterface,
  SensorCRUDUpdateRequestInterface,
  SensorFormDropdownData,
  SitesDropdownOption,
} from '../../../store/sensor-management/sensor-management.model';
import { ISensorManagementCrudRequestConstructionParameters } from '../../../view/settings/sensor-management/sensor-management.model';
import moment from 'moment';
import { ICreateExcel, ICreateExcelSheet } from '../excel/excel.helper';
import { ECellTypes, EExcelColumnWidth, EExcelSheetType } from '../excel/excel.enum';
import { ExcelHelperService } from '../excel/excel.helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { excelDateFormat, excelTimeFormat } from '../../model/enum/excel-date-format';
import * as ObjectActions from '../../../store/sensor-management/sensor-management.actions';
import { ValueType } from 'exceljs';
import { HTTPHelperService } from '../http/http.helper.service';
import { LineCRUDInterface, SiteCRUDInterface } from '../../component/filter/filter.class';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { DECIMAL_NUMERIC_SCALE, sensorTypeTranslations } from '../../../../constants';
import * as _ from 'lodash';
import * as AppActions from '../../../store/app/actions';

@Injectable({
  providedIn: 'root',
})
export class SensorManagementService {
  private readonly routes = {
    sensorList: '/sensor-list',
    lines: '/lines',
    sites: '/sites',
  };

  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private locale$: string;
  private dateTimeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public helperService: HelperService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly translate: TranslateService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly httpHelperService: HTTPHelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
            this.locale$ = state.locale;
            this.dateTimeFormat$ = state.dateTimeFormat;
          }
        }
      });
  }

  public loadSensors(
    serviceParameters: ISensorManagementCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<SensorCRUDInterface>> {
    let params: HttpParams = this.helperService.insertGenericCrudRequestParameters(serviceParameters);
    params = params.set('isOnlyActiveSensors', serviceParameters?.isOnlyActiveSensors ? 'true' : 'false');

    for (const filters of serviceParameters?.advancedFilter?.filters ?? []) {
      switch (filters.path) {
        case 'equipment.equipmentName':
          params = params.append('join', 'equipment.equipmentName');
          break;
        case 'lineStation.name':
          params = params.append('join', 'lineStation.name');
          break;
        default:
          break;
      }
    }
    return this.http.get<GetManyResponseInterface<SensorCRUDInterface>>(`${this.api}${this.routes.sensorList}`, {
      params,
    });
  }

  public loadFormData(): Observable<SensorFormDropdownData> {
    const siteParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['name', 'decimalScaleLimit'],
    });

    const lineParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      join: ['station', 'equipmentAssignment', 'equipmentAssignment.equipment'],
      fields: ['title', 'siteId'],
    });

    return forkJoin({
      sites: this.http.get<GetManyResponseInterface<SitesDropdownOption>>(`${this.api}/sites`, {
        params: siteParams,
      }),
      lines: this.http.get<GetManyResponseInterface<LineDropdownOption>>(`${this.api}/lines`, { params: lineParams }),
      hexboxDataOutputs: this.http.get<GetManyResponseInterface<IHexboxDataOutput>>(`${this.api}/hexbox-data-outputs`),
    });
  }

  public createSensor(
    requestData: SensorCRUDCreateRequestInterface,
  ): Observable<BaseOneResponseInterface<SensorCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<SensorCRUDInterface>>(
      `${this.api}${this.routes.sensorList}`,
      requestData,
    );
  }

  public updateSensor(
    id: number,
    requestData: SensorCRUDUpdateRequestInterface,
  ): Observable<BaseOneResponseInterface<SensorCRUDInterface>> {
    return this.http.patch<BaseOneResponseInterface<SensorCRUDInterface>>(
      `${this.api}${this.routes.sensorList}/${id}`,
      requestData,
    );
  }

  public bulkUpdateSensor(requestData: SensorBulkUpdateRequestInterface[]): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.api}${this.routes.sensorList}/bulk/edit`, {
      sensorLists: requestData,
    });
  }

  public deleteSensor(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}${this.routes.sensorList}/${id}`);
  }

  public bulkDeleteSensor(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.sensorList}/bulk/delete`, {
      body: { sensorLists: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public downloadSensorsExcel(
    filters: ISensorManagementCrudRequestConstructionParameters,
    page: number,
    limit: number = 1000,
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());

    const sourcesObject: { [key: string]: Observable<any> } = {
      sensors: this.loadSensors({ ...filters, page: page ?? 1, perPage: limit }),
    };

    forkJoin(sourcesObject)
      .pipe(
        concatMap((response: any) => {
          const sensors: SensorCRUDInterface[] = response.sensors?.data ?? response.sensors;

          const siteIds: number[] = Array.from(
            new Set(sensors.filter((sensor) => sensor.siteId).map((sensor) => sensor.siteId)),
          );
          const lineIds: number[] = Array.from(
            new Set(sensors.filter((sensor) => sensor.lineId).map((sensor) => sensor.lineId)),
          );

          return forkJoin({
            sensors: of({ data: sensors, success: true }),
            sites: this.loadSites(siteIds, limit),
            lines: this.loadLines(lineIds, limit),
          });
        }),
      )
      .subscribe({
        next: (response: {
          sensors: GetManyResponseInterface<SensorCRUDInterface>;
          sites: GetManyResponseInterface<SiteCRUDInterface>;
          lines: GetManyResponseInterface<LineCRUDInterface>;
        }) => {
          const mappedData: {
            siteNameMap: Map<number, string>;
            lineNameMap: Map<number, string>;
            stationNameMap: Map<number, string>;
            equipmentNameMap: Map<number, string>;
          } = this.getMappedExcelRelationalData(response.sites, response.lines);

          const sheetTitle: string = this.translate.instant('excel.items.sensors');
          const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
          const excelData: ISensorExcelData[] = this.mapExcelData(
            response.sensors.data,
            mappedData.siteNameMap,
            mappedData.lineNameMap,
            mappedData.stationNameMap,
            mappedData.equipmentNameMap,
          );

          this.createExcel(excelName, excelData, sheetTitle).then(
            () =>
              of(this.store.dispatch(new ObjectActions.DownloadSensorsExcelCompleted()), new AppActions.HideLoader()),
            (error) => of(this.store.dispatch(new ObjectActions.FetchError(error)), new AppActions.HideLoader()),
          );
        },
        error: (error) => of(new ObjectActions.FetchError(error), new AppActions.HideLoader()),
      });
  }

  private getSensorExcelColumns(): ICreateExcel {
    const excelColumns: ICreateExcel = {
      columns: [
        {
          header: 'id',
          key: 'id',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.site'),
          key: 'siteName',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.lineName'),
          key: 'lineName',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.lineStation'),
          key: 'lineStationName',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.sensorId'),
          key: 'sensorId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.sensorType'),
          key: 'sensorType',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensor.management.cardReaderType.types'),
          key: 'cardReaderType',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.cardReaderDisplayType'),
          key: 'cardReaderDisplayType',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.description'),
          key: 'sensorName',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.sensorLowerThreshold'),
          key: 'sensorLowerThreshold',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.sensorUpperThreshold'),
          key: 'sensorUpperThreshold',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.microstopLowerThreshold'),
          key: 'microstopLowerThreshold',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.microstopUpperThreshold'),
          key: 'microstopUpperThreshold',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.input.sensorCountUpperLimit.label'),
          key: 'sensorCountUpperLimit',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.input.setSensorCountValue.label'),
          key: 'setSensorCountValue',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.startDate'),
          key: 'startDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.endDate'),
          key: 'endDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.sensorManufacturer'),
          key: 'sensorManufacturer',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('sensorManagement.listView.headers.equipmentName'),
          key: 'equipmentName',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelperService.prepareExcelColumns(excelColumns.columns);

    return excelColumns;
  }

  private loadSites(siteIds: number[], limit: number = 1000): Observable<GetManyResponseInterface<SiteCRUDInterface>> {
    if (!siteIds.length) {
      return of({ data: [], success: true });
    }

    let params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters({
      limit,
      fields: ['id', 'name'],
    });

    params = params.set(
      's',
      JSON.stringify({
        $and: [{ statusId: { $eq: 1 } }, { id: { $in: siteIds } }],
      }),
    );

    return this.http.get<GetManyResponseInterface<SiteCRUDInterface>>(`${this.api}${this.routes.sites}`, {
      params,
    });
  }

  private loadLines(lineIds: number[], limit: number = 1000): Observable<GetManyResponseInterface<LineCRUDInterface>> {
    if (!lineIds.length) {
      return of({ data: [], success: true });
    }

    let params: HttpParams = this.httpHelperService.insertGenericCrudRequestParameters({
      limit,
      fields: ['id', 'title', 'station.name', 'equipmentAssignment.equipment.equipmentName'],
      join: ['station', 'equipmentAssignment', 'equipmentAssignment.equipment'],
    });

    params = params.set('s', JSON.stringify({ id: { $in: lineIds } }));

    return this.http.get<GetManyResponseInterface<LineCRUDInterface>>(`${this.api}/lines`, {
      params,
    });
  }

  private getMappedExcelRelationalData(
    sites: GetManyResponseInterface<SiteCRUDInterface>,
    lines: GetManyResponseInterface<LineCRUDInterface>,
  ): {
    siteNameMap: Map<number, string>;
    lineNameMap: Map<number, string>;
    stationNameMap: Map<number, string>;
    equipmentNameMap: Map<number, string>;
  } {
    const siteNameMap: Map<number, string> = new Map<number, string>();
    const lineNameMap: Map<number, string> = new Map<number, string>();
    const stationNameMap: Map<number, string> = new Map<number, string>();
    const equipmentNameMap: Map<number, string> = new Map<number, string>();

    sites.data.forEach((site) => {
      siteNameMap.set(site.id, site.name);
    });

    lines.data.forEach((line) => {
      lineNameMap.set(line.id, line.title);

      if (line.station && line.station.length) {
        line.station.forEach((station) => stationNameMap.set(station.id, station.name));
      }

      if (line.equipmentAssignment && line.equipmentAssignment.length) {
        line.equipmentAssignment.forEach((equipmentAssignment) => {
          equipmentNameMap.set(equipmentAssignment.equipment.id, equipmentAssignment.equipment.equipmentName);
        });
      }
    });

    return { siteNameMap, lineNameMap, stationNameMap, equipmentNameMap };
  }

  private mapExcelData(
    sensors: SensorCRUDInterface[],
    siteNameMap: Map<number, string>,
    lineNameMap: Map<number, string>,
    stationNameMap: Map<number, string>,
    equipmentNameMap: Map<number, string>,
  ): ISensorExcelData[] {
    return sensors.map((sensor) => {
      return {
        id: sensor.id,
        siteName: siteNameMap.get(sensor.siteId) ?? '',
        lineName: lineNameMap.get(sensor.lineId) ?? '',
        lineStationName: stationNameMap.get(sensor.lineStationId) ?? '',
        sensorId: sensor.sensorId,
        sensorType: this.translate.instant(sensorTypeTranslations[sensor.sensorType]),
        cardReaderType: !_.isNil(sensor.cardReaderType)
          ? this.translate.instant(cardReaderTypeTranslations[sensor.cardReaderType])
          : null,
        cardReaderDisplayType: !_.isNil(sensor.cardReaderDisplayType)
          ? this.translate.instant(cardReaderDisplayTypeTranslations[sensor.cardReaderDisplayType])
          : null,
        description: sensor.sensorName,
        sensorLowerThreshold: sensor.sensorLowerThreshold,
        sensorUpperThreshold: sensor.sensorUpperThreshold,
        microstopLowerThreshold: sensor.microstopLowerThreshold,
        microstopUpperThreshold: sensor.microstopUpperThreshold,
        sensorCountUpperLimit: this.decimalHelper.toFixedValue(sensor.sensorCountUpperLimit, DECIMAL_NUMERIC_SCALE),
        setSensorCountValue: this.decimalHelper.toFixedValue(sensor.setSensorCountValue, DECIMAL_NUMERIC_SCALE),
        startDate: moment(this.helperService.convertFromISOFormatToGivenTimezone(sensor.startDate))
          .locale(this.locale$)
          .format(this.dateTimeFormat$),
        endDate: moment(this.helperService.convertFromISOFormatToGivenTimezone(sensor.endDate))
          .locale(this.locale$)
          .format(this.dateTimeFormat$),
        sensorManufacturer: sensor.sensorManufacturer
          ? this.translate.instant(`sensor.management.manufacturer.${sensor.sensorManufacturer}`)
          : null,
        equipmentName: equipmentNameMap.get(sensor.equipmentId) ?? '',
      };
    }) as unknown as ISensorExcelData[];
  }

  private createExcel(excelName: string, excelData: ISensorExcelData[], sheetTitle: string): Promise<void> {
    const excelOptions: ICreateExcel = this.getSensorExcelColumns();

    excelOptions.data = excelData;

    const worksheets: ICreateExcelSheet[] = [
      {
        withData: true,
        sheetTitle,
        sheetType: EExcelSheetType.TABLE,
        params: excelOptions,
        isDisabledColumnsFirstLine: true,
      },
    ];

    return this.excelHelperService.createExcel(
      excelName,
      { withData: true, name: 'sensors' },
      worksheets,
      this.timezone,
      this.dateFormat$,
      this.timeFormat$,
    );
  }
}
